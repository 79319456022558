import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { createWordAndAddToStudySet } from 'services/Teacher/Vocabulary.service';

const AddNewWordModal = ({ show, handleClose, studySetId, reload }) => {
      const [loading, setLoading] = useState(false);
    const [newWordDetails, setNewWordDetails] = useState({
        title: "",
        translations: "",
        sentence: "",
        difficulty: "Beginner",
        meaning: "",
        classType: "noun",
    });

    const handleCreateNewWord = async () => {
        const { title, translations, sentence, difficulty, meaning, classType } = newWordDetails;

        // Validation check for required fields
        if (!title || !translations || !studySetId) {
            alert("Please fill in all required fields.");
            return;
        }

        const payload = {
            title,
            translations,
            sentence,
            difficulty,
            meaning,
            language: translations,  // assuming translation is for target language
            creationMethod: "manual",  // assuming manual creation method
            classType,
        };

        try {
            setLoading(true);
            await createWordAndAddToStudySet(studySetId, payload);
            reload();
            handleClose(); // Close the modal
        } catch (error) {
            console.error("Error creating word:", error);
        } finally{
            setLoading(false);
        }
    };

    return (
        <Modal show={show} centered onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Add New Word</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-2' controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={newWordDetails.title}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, title: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group className='mb-2' controlId="formTranslations">
                        <Form.Label>Translation</Form.Label>
                        <Form.Control
                            type="text"
                            value={newWordDetails.translations}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, translations: e.target.value })}
                            required
                        />
                    </Form.Group>

                    <Form.Group className='mb-2' controlId="formSentence">
                        <Form.Label>Sentence</Form.Label>
                        <Form.Control
                            type="text"
                            value={newWordDetails.sentence}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, sentence: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className='mb-2' controlId="formMeaning">
                        <Form.Label>Meaning</Form.Label>
                        <Form.Control
                            type="text"
                            value={newWordDetails.meaning}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, meaning: e.target.value })}
                        />
                    </Form.Group>

                    <Form.Group className='mb-2' controlId="formDifficulty">
                        <Form.Label>Difficulty</Form.Label>
                        <Form.Control
                            as="select"
                            value={newWordDetails.difficulty}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, difficulty: e.target.value })}
                        >
                            <option value="Beginner">Beginner</option>
                            <option value="Elementary">Elementary</option>
                            <option value="Intermediate">Intermediate</option>
                            <option value="Advanced">Advanced</option>
                            <option value="Expert">Expert</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className='mb-2' controlId="formClassType">
                        <Form.Label>Class Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={newWordDetails.classType}
                            onChange={(e) => setNewWordDetails({ ...newWordDetails, classType: e.target.value })}
                        >
                            <option value="noun">Noun</option>
                            <option value="verb">Verb</option>
                            <option value="adjective">Adjective</option>
                        </Form.Control>
                    </Form.Group>
                    <div className='text-end'>
                    <Button disabled={loading} variant="primary" className='button-style mt-3' onClick={handleCreateNewWord}>
                        Create Word
                    </Button>
                    </div>
                    
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddNewWordModal;
