import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import { Modal, Button } from "react-bootstrap";

const SebInstructionsModal = ({ show, icon = faInfo, handleClose, handleDownload, loadingSeb }) => {
    return (
        <Modal show={show} onHide={handleClose} centered size={"md"} backdrop='static'>
            <Modal.Header className="border-0" closeButton>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <FontAwesomeIcon
                    className='p-3'
                    icon={icon}
                    style={{
                        fontSize: "40px",
                        color: "red",
                        border: "3px solid red",
                        borderRadius: "60px",
                        width: "40px",
                        height: "40px"
                    }}
                />
                <h3 className='mt-1'>SEB Instructions</h3>
                <p className='text-muted text-start'>
                    To take this quiz, you must have the <b style={{ color: 'var(--primary-color)' }}>Safe Exam Browser (SEB)</b> installed on your device.
                </p>
                <p className='text-muted text-start mb-0'>
                    <b>Instructions:</b>
                </p>
                <ul className='text-muted text-start'>
                    <li>
                        If SEB is not installed, you can download it by clicking
                        <a
                            className="ps-1 text-decoration-none"
                            href="https://github.com/SafeExamBrowser/seb-win-refactoring/releases/download/v3.8.0/SEB_3.8.0.742_SetupBundle.exe"
                            target="_blank"
                        >
                            Here.
                        </a>
                    </li>
                    <li>
                        Once installed, open the <b style={{ color: 'var(--primary-color)' }}>.seb</b> file you just downloaded using SEB to start the assignment.
                    </li>
                    <li>
                        Ensure no other applications or browsers are running while using SEB.
                    </li>
                    <li>
                        This assignment can only be <b style={{ color: 'var(--primary-color)' }}> attempted once.</b> Ensure you are fully prepared before starting.
                    </li>
                    <li>
                        Once you start the assignment, it will be <b style={{ color: 'var(--primary-color)' }}>auto-submitted</b> after <b style={{ color: 'var(--primary-color)' }} s>8 hours</b> if you quit SEB without submitting. Do not quit SEB unless you are ready to submit. Ensure to only quit SEB after completing and submitting the assignment.
                    </li>
                </ul>
                {/* <div className="text-center">
                    <Button
                        variant="primary"
                        href="https://github.com/SafeExamBrowser/seb-win-refactoring/releases/download/v3.8.0/SEB_3.8.0.742_SetupBundle.exe"
                        target="_blank"
                    >
                        Download SEB
                    </Button>
                </div> */}
            </Modal.Body>
            <Modal.Footer className="border-0 pt-0 justify-content-between">
                <Button
                    variant="secondary"
                    type='button'
                    className='mt-1 px-4 button-style py-2 border-0'
                    style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                    onClick={handleClose}
                    disabled={loadingSeb}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    type='button'
                    className='mt-1 px-4 button-style py-2 border-0'
                    onClick={handleDownload}
                    disabled={loadingSeb}
                >
                    {loadingSeb ? "Downloading" : 'Download'}
                    {/* Download */}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SebInstructionsModal;
