import { GetApiData } from "utils/http-client";

export const createSubmission = async (data, status) => {
    return await GetApiData(`/submission?status=${status || ""}`, 'POST', data, true);
};

export const getSubmission = async (id) => {
    return await GetApiData(`/submission/single/${id}`, 'GET', null, true);
};
export const generateSebFile = async (id) => {
    return await GetApiData(`/submission/generate-seb/${id}`, "GET", null, true, "blob");
}
export const terminatedAssignment = async (id) => {
    return await GetApiData(`/submission/terminated/${id}`, 'PUT', true);
};
export const saveProgress = async (data) => {
    return await GetApiData(`/submission/save`, 'POST', data, true);
};

export const submitAssignment = async (data) => {
    return await GetApiData(`/submission/submit`, 'POST', data, true);
};

export const markSubmission = async (data) => {
    return await GetApiData(`/submission/mark`, 'PUT', data, true);
};

export const sendToRevision = async (data) => {
    return await GetApiData(`/submission/revise`, 'POST', data, true);
};


export const forceSubmitAssignment = async (id) => {
    return await GetApiData(`/submission/force/${id}`, 'POST', null, true);
};