import { GetApiData } from "utils/http-client";

export const getAllVocabulary = async (page=1) => {
    return await GetApiData(`/vocabulary/studyset?page=${page}`, 'GET', null, true);
};
export const getAssignedStudysets = async (page=1) => {
    return await GetApiData(`/assignment/studysets?page=${page}`, 'GET', null, true);
};
export const createWordAndAddToStudySet = async (id,data) => {
    return await GetApiData(`/vocabulary/studyset/word/${id}`, 'POST', data, true);
};
export const getVocabularyById = async (id,assignmentId="") => {
    return await GetApiData(`/vocabulary/studyset/${id}?assignmentId=${assignmentId}`, 'GET', null, true);
};
export const getVocabularyQuizStats = async (id) => {
    return await GetApiData(`/vocabulary/quiz/stats/${id}`, 'GET', null, true);
};
export const updateVocabularyId = async (id,data) => {
    return await GetApiData(`/vocabulary/studyset/${id}`, 'PUT', data, true);
};
export const createStudySet = async (data) => {
    return await GetApiData(`/vocabulary/studyset`, 'POST', data, true);
};
export const createAIStudySet = async (data) => {
    return await GetApiData(`/vocabulary/studyset/ai`, 'POST', data, true);
};
export const deleteStudySet = async (id) => {
    return await GetApiData(`/vocabulary/studyset/${id}`, 'DELETE', null, true);
};
export const createWord = async (data) => {
    return await GetApiData(`/vocabulary/word`, 'POST', data, true);
};
export const deleteWordFromStudySet = async (studysetId,wordId) => {
    return await GetApiData(`/vocabulary/studyset/word/${studysetId}/${wordId}`, 'DELETE', null, true);
};
export const getAllWords = async (page,limit,search) => {
    return await GetApiData(`/vocabulary/word?page=${page}&limit=${limit}&search=${search}`, 'GET', null, true);
};