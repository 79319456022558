import { faAdd, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddNewWordModal from "components/modals/AddNewWordModal";
import AllWordsModal from "components/modals/AllWordsModal";
import ExerciseInformationModal from "components/modals/ExerciseInformationModal";
import PageHeading from "components/shared/PageHeading";
import React, { useEffect, useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Placeholder } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getVocabularyById, updateVocabularyId } from "services/Teacher/Vocabulary.service";
import VocabularyListReports from "views/students/Vocabulary/VocabularyListReports";

const VocabularyDetail = () => {
  const [studySetDetail, setStudySetDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updatedStudySetDetail, setUpdatedStudySetDetail] = useState([]);
  const [targetLanguageTitle, setTargetLanguageTitle] = useState("");
  const [sourceLanguageTitle, setSourceLanguageTitle] = useState("");
  const [showexerciseDetail, setShowexerciseDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseexerciseDetailModal = () => setShowexerciseDetail(false);
  const handleShowexerciseDetailModal = () => setShowexerciseDetail(true);

  const params = useParams();
  const id = params.id;

  const fetchStudySetDetails = async () => {
    setLoading(true);
    try {
      const response = await getVocabularyById(id);
      setStudySetDetail(response?.data?.result?.words);
      setUpdatedStudySetDetail(response?.data?.result?.words);
      setTargetLanguageTitle(response?.data?.result?.targetLanguage?.title); // Set the target language title
      setSourceLanguageTitle(response?.data?.result?.sourceLanguage?.title); // Set the source language title
    } catch (error) {
      console.error("Error fetching Study Set details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudySetDetails();
  }, [id]);

  const handleAddWord = async (newWordId) => {
    const updatedWords = [...updatedStudySetDetail, newWordId];
    try {
      await updateVocabularyId(id, { words: updatedWords });
      await fetchStudySetDetails();
    } catch (error) {
      console.error("Error adding word to Study Set:", error);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div>
          <PageHeading heading={"Vocabulary"} />
        </div>
        <div>
          <div className="d-flex align-items-center gap-3">
            <Button
              className=" px-4 button-style py-2 border-0"
              // disabled={loading}
              style={{
                background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
              }}
              onClick={handleShowexerciseDetailModal}
            >
              <FontAwesomeIcon icon={faUsers} /> Assign
            </Button>

            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-custom-components"
                variant="secondary"
                className="button-style border-0 py-2"
              >
                <FontAwesomeIcon className="pe-2" icon={faAdd} />
                Add words
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleShowModal}>
                  Add New Word
                </Dropdown.Item>
                {/* <Dropdown.Item onClick={handleShow}>Add from Library</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {loading ? (
        <Card className="border-0 mt-5">
          <Placeholder animation="glow">
            <Placeholder xs={12} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={9} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={12} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={6} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={9} height={200} />
          </Placeholder>
          <Placeholder animation="glow">
            <Placeholder xs={12} height={200} />
          </Placeholder>
        </Card>
      ) : (
        <Card className="border-0">
          <VocabularyListReports
            studySetDetail={updatedStudySetDetail}
            targetLanguageTitle={targetLanguageTitle} // Passing the target language title
            sourceLanguageTitle={sourceLanguageTitle} // Passing the source language title
            studySetId={id}
            reload={fetchStudySetDetails}
          />
        </Card>
      )}

      {/* Modal for adding new word */}
      <AddNewWordModal
        show={showModal}
        handleClose={handleCloseModal}
        studySetId={id}
        addWordToVocabulary={handleAddWord}
        reload={fetchStudySetDetails}
      />

      {/* Add from Library Modal (existing functionality) */}
      <AllWordsModal
        show={show}
        handleClose={handleClose}
        addWordToVocabulary={handleAddWord}
      />
      <ExerciseInformationModal
        show={showexerciseDetail}
        onHide={handleCloseexerciseDetailModal}
        type="studySet"
      />
    </>
  );
};

export default VocabularyDetail;
