import React, { useState, useEffect } from "react";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import InputComponent from "components/shared/InputComponent";
import Select from "components/shared/Select";
import { createAIStudySet } from "services/Teacher/Vocabulary.service"; // Assuming this API exists
import { getAllSubject } from "services/Teacher/Exercise.service"; // Fetch languages API
import { useNavigate } from "react-router-dom";

const AiGeneratedVocabulary = () => {
  const [theme, setTheme] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [studySetSize, setStudySetSize] = useState(5);
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [translationLanguage, setTranslationLanguage] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true); // To handle form validation
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Fetch subjects when the component mounts
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await getAllSubject();
        const formattedSubjects = response.data.results.subject.map((subject) => ({
          value: subject._id,
          label: subject.title,
        }));
        setSubjects(formattedSubjects);
        setSourceLanguage(formattedSubjects[0]?.value || "");
        setTranslationLanguage(formattedSubjects[1]?.value || "");
      } catch (error) {
        console.error("Error fetching subjects:", error);
      }
    };

    fetchSubjects();
  }, []);

  // Handle the form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    let validationErrors = {};

    // Validate all required fields
    if (!theme) {
      validationErrors.theme = "Theme is required";
    }

    if (!sourceLanguage) {
      validationErrors.sourceLanguage = "Source language is required";
    }

    if (!translationLanguage) {
      validationErrors.translationLanguage = "Target language is required";
    }

    if (sourceLanguage === translationLanguage) {
      validationErrors.languageMismatch = "Source and Target languages cannot be the same";
    }

    if (!difficultyLevel) {
      validationErrors.difficultyLevel = "Difficulty level is required";
    }

    if (studySetSize < 5 || studySetSize > 20) {
      validationErrors.studySetSize = "Study Set Size should be between 5 and 20";
    }

    setErrors(validationErrors);

    // If there are validation errors, stop the form submission
    if (Object.keys(validationErrors).length > 0) {
      setIsFormValid(false);
      return;
    }

    const payload = {
      theme,
      difficultyLevel,
      studySetSize,
      sourceLanguage,
      translationLanguage,
    };

    const toastId = toast.loading("Generating Study Set...");

    try {
      setLoading(true);
      const response = await createAIStudySet(payload);
      toast.update(toastId, {
        render: "Study set generated successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      navigate(`/vocabulary/${response.data?.result?.studySet._id}`);
    } catch (error) {
      console.error("Error generating study set:", error);
      toast.update(toastId, {
        render: "Error generating Study set.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      })
    }
    finally {
      setLoading(false);
    }
  };

  // Predefined Themes
  const themeOptions = [
    { value: "", label: "Select Theme" },
    { value: "Animals", label: "Animals" },
    { value: "Technology", label: "Technology" },
    { value: "Food", label: "Food" },
    { value: "Nature", label: "Nature" },
    { value: "Sports", label: "Sports" }
  ];

  return (
    <>
      <Card className="border-0 shadow p-3">
        <Form>
          <Select
            heading={"Theme"}
            value={theme}
            onChange={setTheme}
            options={themeOptions}
            ariaLabel="Select theme"
            required
            isInvalid={!!errors.theme}
          />
          {errors.theme && <p style={{ color: 'red' }}>{errors.theme}</p>}

          <Select
            heading={"Source Language"}
            value={sourceLanguage}
            onChange={setSourceLanguage}
            options={subjects}
            ariaLabel="Select source language"
            required
            isInvalid={!!errors.sourceLanguage}
          />
          {errors.sourceLanguage && <p style={{ color: 'red' }}>{errors.sourceLanguage}</p>}

          <Select
            heading={"Target Language"}
            value={translationLanguage}
            onChange={setTranslationLanguage}
            options={subjects}
            ariaLabel="Select target language"
            required
            isInvalid={!!errors.translationLanguage || !!errors.languageMismatch}
          />
          {errors.translationLanguage && <p style={{ color: 'red' }}>{errors.translationLanguage}</p>}
          {errors.languageMismatch && <p style={{ color: 'red' }}>{errors.languageMismatch}</p>}

          <Select
            heading={"Difficulty Level"}
            value={difficultyLevel}
            onChange={setDifficultyLevel}
            options={[
              { value: "", label: "Select Difficulty" },
              { value: "Beginner", label: "Beginner" },
              { value: "Elementary", label: "Elementary" },
              { value: "Intermediate", label: "Intermediate" },
              { value: "Advanced", label: "Advanced" },
              { value: "Expert", label: "Expert" },
            ]}
            ariaLabel="Select difficulty level"
            required
            isInvalid={!!errors.difficultyLevel}
          />
          {errors.difficultyLevel && <p style={{ color: 'red' }}>{errors.difficultyLevel}</p>}

          {/* Study Set Size as Slider */}
          <Col xs={12} lg={6}>
            <Form.Group controlId="studySetSize">
              <Form.Label>Study Set Size: {studySetSize}</Form.Label>
              <Form.Range
                min={5}
                max={20}
                value={studySetSize}
                onChange={(e) => setStudySetSize(e.target.value)}
              />
            </Form.Group>
            {errors.studySetSize && <p style={{ color: 'red' }}>{errors.studySetSize}</p>}
          </Col>

          <div className="d-flex justify-content-end mt-3 ">
            <div>
              <Button disabled={loading} className="button-style border-0 py-2 px-4" onClick={handleSubmit}>
                Generate
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AiGeneratedVocabulary;
