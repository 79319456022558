import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import InputComponent from "components/shared/InputComponent";
import SelectReact from "react-select";
import { getAllClasses } from "services/Teacher/Class.service";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { Assignments } from "services/Teacher/Assignments.service";
import { getClassTeachersAndStudents } from "services/Teacher/ClassesAndGroups.service";
import { getAllTeachersAndStudents } from "services/Teacher/User.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const ExerciseInformationModal = ({ show, onHide, exerciseId, type = "exercise" }) => {
  const params = useParams();
  const [formState, setFormState] = useState({
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    maxTime: "",
    safeBrowser: false,
    forceSubmission: false,
    sources: [], // Array of sources
  });
  const [classes, setClasses] = useState([]);
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]); // All available students
  const [selectedStudents, setSelectedStudents] = useState({});
  const [loading, setLoading] = useState(false);
  const [maxTimeEnabled, setMaxTimeEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schoolId = JSON.parse(localStorage.getItem("user"))?.school;

  useEffect(() => {
    if (show && schoolId) {
      fetchClassesAndGroups();
    }
  }, [show, schoolId]);

  const fetchClassesAndGroups = async () => {
    try {
      setLoading(true);
      const response = await getAllClasses(schoolId);
      setClasses(response.data.result.classes.data || []);
      setGroups(response.data.result.groups.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching classes/groups:", error);
    }
  };

  const fetchStudents = async (sourceId, index, sourceType) => {
    try {
      setLoading(true);

      let fetchedStudents = [];
      if (sourceType === "individual") {
        const response = await getAllTeachersAndStudents(schoolId, "student");
        fetchedStudents = response?.data.users || [];
      } else {
        const response = await getClassTeachersAndStudents(sourceId);
        fetchedStudents = response?.data.results || [];
      }

      const studentOptions = fetchedStudents.map((student) => ({
        value: student._id,
        label: student.fullName,
      }));

      setStudents(studentOptions);

      // Do not overwrite selectedStudents unless it's a new class/group
      if (sourceType !== "individual") {
        setSelectedStudents((prev) => ({
          ...prev,
          [index]: studentOptions,
        }));

        const updatedSources = [...formState.sources];
        updatedSources[index].selectedStudents = studentOptions;

        // updatedSources[index].selectedStudents = [];
        setFormState((prevState) => ({
          ...prevState,
          sources: updatedSources,
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching students:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStudentSelection = (index, selected) => {
    // Update the selected students for the specific source
    const updatedSources = [...formState.sources];
    updatedSources[index].selectedStudents = selected;
    setFormState((prevState) => ({
      ...prevState,
      sources: updatedSources,
    }));

    // Optionally update selectedStudents state if needed
    setSelectedStudents((prev) => ({
      ...prev,
      [index]: selected,
    }));
  };

  const handleSourceChange = (index, field, value) => {
    const updatedSources = [...formState.sources];
    updatedSources[index][field] = value;

    if (field === "sourceType") {
      // Reset sourceId and selectedStudents when sourceType changes
      updatedSources[index].sourceId = "";
      setSelectedStudents((prev) => ({
        ...prev,
        [index]: [], // Clear selected students for this source
      }));
      setStudents([]); // Clear students in the dropdown options if switching type
    }

    if (
      field === "sourceId" &&
      ["class", "group"].includes(updatedSources[index].sourceType)
    ) {
      // Fetch and auto-select students for the new class/group
      fetchStudents(value, index, updatedSources[index].sourceType);
    } else if (field === "sourceType" && value === "individual") {
      // Fetch and auto-select students for individual source type
      fetchStudents(null, index, "individual");
    }

    setFormState((prevState) => ({
      ...prevState,
      sources: updatedSources,
    }));
  };

  const addSource = () => {
    setFormState((prevState) => ({
      ...prevState,
      sources: [
        ...prevState.sources,
        { sourceType: "class", sourceId: "", selectedStudents: [] },
      ],
    }));
  };

  const handleModalClose = () => {
    // Reset all states to their initial values
    setFormState({
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      maxTime: "",
      forceSubmission: false,
      safeBrowser: false,
      sources: [], // Reset sources
    });
    setStudents([]); // Clear students list
    setSelectedStudents([]);
    setClasses([]); // Clear classes list
    setGroups([]); // Clear groups list
    setMaxTimeEnabled(false); // Reset maxTime toggle
    onHide(); // Call parent-provided onHide function
  };

  const removeSource = (index) => {
    setFormState((prevState) => ({
      ...prevState,
      sources: prevState.sources.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formState.name) errors.name = "Title is required";
    if (!formState.startDate) errors.startDate = "Start date is required";
    if (!formState.endDate) errors.endDate = "End date is required";

    if (formState.sources.length === 0) {
      errors.sources = "At least one source is required";
    } else {
      const hasStudents = formState.sources.some(
        (source) =>
          source.selectedStudents && source.selectedStudents.length > 0
      );

      if (!hasStudents) {
        errors.students = "At least one student must be assigned";
      }
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      if (errors.sources) toast.error(errors.sources);
      if (errors.students) toast.error(errors.students);
      return;
    }

    setIsSubmitting(true);

    const payload = {
      title: formState.name,
      exercise: type === "exercise" ? exerciseId || params.id : null,
      studySet: type === "studySet" ? exerciseId || params.id : null,
      type: type,
      startDate: formState.startDate,
      endDate: formState.endDate,
      maxTime: maxTimeEnabled ? formState.maxTime : null,
      forceSubmission: formState.forceSubmission,
      safeBrowser: formState.safeBrowser,
      sources: formState.sources.map((source) => ({
        sourceType: source.sourceType === "group" ? "class" : source.sourceType,
        sourceId: source.sourceId,
        selectedStudents: source.selectedStudents.map(
          (student) => student.value
        ), // Use only the explicitly selected students
      })),
    };

    const toastId = toast.loading("Assigning assignment...");
    try {
      await Assignments(payload);
      toast.update(toastId, {
        render: "Assignment assigned successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      handleModalClose();
    } catch (error) {
      console.error("Error assigning assignment:", error);
      toast.update(toastId, {
        render: "Error assigning assignment",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSafeBrowserToggle = () => {
    setFormState((prev) => ({
      ...prev,
      safeBrowser: !prev.safeBrowser,
    }));
  };
  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      centered
      size="lg"
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Assign Exercise</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <InputComponent
            label="Assignment Title"
            placeholder="Enter assignment title"
            value={formState.name}
            setValue={(value) => handleInputChange("name", value)}
          />
        </div>

        <Row>
          <Col xs={6}>
            <Form.Label>Start Date</Form.Label>
            <DatePicker
              selected={formState.startDate}
              showTimeSelect={true}
              minDate={new Date()}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              onChange={(date) => handleInputChange("startDate", date)}
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control"
            />
          </Col>
          <Col xs={6}>
            <Form.Label>End Date</Form.Label>
            <DatePicker
              selected={formState.endDate}
              showTimeSelect={true}
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              minDate={formState.startDate ? formState.startDate : new Date()}
              onChange={(date) => handleInputChange("endDate", date)}
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={6}>
            <Form.Label>Time Limit</Form.Label>
            <Form.Check
              type="switch"
              label="Enable Time Limit"
              checked={maxTimeEnabled}
              onChange={(e) => setMaxTimeEnabled(e.target.checked)}
            />
            <InputComponent
              className="w-50 pe-2 mt-3"
              type="number"
              placeholder="Enter time limit in minutes"
              value={formState.maxTime}
              setValue={(value) => handleInputChange("maxTime", value)}
              disabled={!maxTimeEnabled}
            />
          </Col>
          <Col xs={6}>
            <Form.Label>Safe Browser</Form.Label>
            <Form.Check
              type="switch"
              label="Enable Safe Browser"
              checked={formState.safeBrowser}
              onChange={handleSafeBrowserToggle}
            />
          </Col>
        </Row>

        {/* <Form.Check
          type="checkbox"
          label="Force Submission"
          className="mt-3"
          checked={formState.forceSubmission}
          onChange={(e) =>
            handleInputChange("forceSubmission", e.target.checked)
          }
        /> */}

        <div className="mt-3">
          <Form.Label className="me-2">Assign to</Form.Label>
          {formState.sources.map((source, index) => (
            <div key={index} className="mb-3 ">
              <div className="d-flex flex-wrap align-items-center justify-content-between mb-2">
                <div className="d-flex flex-wrap gap-2 mb-2">
                  <Form.Check
                    type="radio"
                    label="Class"
                    checked={source.sourceType === "class"}
                    onChange={() =>
                      handleSourceChange(index, "sourceType", "class")
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Group"
                    checked={source.sourceType === "group"}
                    onChange={() =>
                      handleSourceChange(index, "sourceType", "group")
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Individuals"
                    checked={source.sourceType === "individual"}
                    onChange={() =>
                      handleSourceChange(index, "sourceType", "individual")
                    }
                  />
                </div>
                <Button variant="danger" onClick={() => removeSource(index)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </Button>
              </div>

              {(source.sourceType === "class" ||
                source.sourceType === "group") && (
                  <SelectReact
                    placeholder="Select Class/Group"
                    isDisabled={loading}
                    className="mb-3 exercise-select"
                    options={
                      source.sourceType === "class"
                        ? classes.map((cls) => ({
                          value: cls._id,
                          label: cls.title,
                        }))
                        : groups.map((grp) => ({
                          value: grp._id,
                          label: grp.title,
                        }))
                    }
                    onChange={(selected) =>
                      handleSourceChange(index, "sourceId", selected.value)
                    }
                  />
                )}
              <SelectReact
                isMulti
                placeholder="Select Students"
                className="mb-3 exercise-select"
                isDisabled={loading}
                options={students} // Use the global `students` state for options
                value={selectedStudents[index] || []} // Use the specific source's selected students
                onChange={(selected) => handleStudentSelection(index, selected)} // Update selectedStudents
              />
            </div>
          ))}
          <div>
            <Button variant="secondary" className="" onClick={addSource}>
              {formState.sources.length === 0
                ? "Add students"
                : "Add more students"}
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="button-style border-0 px-3"
          style={{
            background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
          }}
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="button-style border-0 px-3"

          onClick={handleSubmit}
          disabled={isSubmitting || loading}
        >
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExerciseInformationModal;
