import React from 'react'
const ErrorSafe = () => {
    return (
        <div>
            <>
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                    <div className='text-center'>
                        <h1 className=" font-bold">404 - Page Not Found</h1>
                    </div>
                </div>

            </>
        </div>
    )
}

export default ErrorSafe
