import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputComponent from 'components/shared/InputComponent';
import { createStudySet } from 'services/Teacher/Vocabulary.service';
import Select from 'components/shared/Select'; // Assuming Select component is imported correctly
import { getAllSubject } from 'services/Teacher/Exercise.service';
import { useNavigate } from 'react-router-dom';

const ManualVocabulary = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const navigate = useNavigate();
    const [rangeValue, setRangeValue] = useState(5);
    const [subjects, setSubjects] = useState([]);
    const [selectedSourceLanguage, setSelectedSourceLanguage] = useState("");
    const [selectedTargetLanguage, setSelectedTargetLanguage] = useState("");
    const [isSourceTargetSame, setIsSourceTargetSame] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true); // To handle form validation
    const [isTitleValid, setIsTitleValid] = useState(true); // For title validation

    // Fetch subjects when the component mounts
    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await getAllSubject();
                const formattedSubjects = response.data.results.subject.map(subject => ({
                    value: subject._id,
                    label: subject.title
                }));
                setSubjects(formattedSubjects);
                // Set default values for the languages if subjects are fetched
                setSelectedSourceLanguage(formattedSubjects[0]?.value || "");
                setSelectedTargetLanguage(formattedSubjects[1]?.value || "");
            } catch (error) {
                console.error("Error fetching subjects:", error);
            }
        };

        fetchSubjects();
    }, []);

    // Handle the form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate title, source, and target language
        if (!title.trim()) {
            setIsTitleValid(false);
            setIsFormValid(false);
            return;
        }

        // Validate that both source and target languages are selected and not the same
        if (!selectedSourceLanguage || !selectedTargetLanguage) {
            setIsFormValid(false);
            return;
        }

        if (selectedSourceLanguage === selectedTargetLanguage) {
            setIsSourceTargetSame(true);
            setIsFormValid(false);
            return;
        }

        const payload = {
            title,
            description,
            maxSize: rangeValue,
            sourceLanguage: selectedSourceLanguage,
            targetLanguage: selectedTargetLanguage,
            difficulty: 'Beginner', // Example difficulty, change as needed
        };

        const toastId = toast.loading('Adding Study set');
        try {
            const response = await createStudySet(payload);
            navigate(`/vocabulary/${response.data?.result?._id}`);
            toast.update(toastId, {
                render: 'Study set successfully created.',
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            });
        } catch (error) {
            console.log("Error:", error);
            toast.update(toastId, {
                render: `Error creating Study set`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });
        }
    };

    return (
        <>
            <Card className='border-0 shadow p-3'>
                <Form>
                    <Row>
                        <Col xs={12} lg={6}>
                            <InputComponent
                                label={"Title"}
                                placeholder={"Study set title"}
                                value={title}
                                setValue={setTitle}
                                type={"text"}
                                isInvalid={!isTitleValid} // Add validation error for title
                            />
                            {!isTitleValid && (
                                <p style={{ color: 'red' }}>Title is required.</p>
                            )}
                        </Col>
                        <Col xs={12} lg={6}>
                            <InputComponent
                                label={"Description"}
                                placeholder={"A short description"}
                                value={description}
                                setValue={setDescription}
                                type={"text"}
                            />
                        </Col>

                        {/* Source Language */}
                        <Col xs={12} lg={6}>
                            <Select
                                heading={"Source Language"}
                                value={selectedSourceLanguage}
                                onChange={setSelectedSourceLanguage}
                                options={subjects}
                                ariaLabel="Select source language"
                                required
                                isInvalid={!isFormValid}
                            />
                        </Col>

                        {/* Target Language */}
                        <Col xs={12} lg={6}>
                            <Select
                                heading={"Target Language"}
                                value={selectedTargetLanguage}
                                onChange={setSelectedTargetLanguage}
                                options={subjects}
                                ariaLabel="Select target language"
                                required
                                isInvalid={!isFormValid || isSourceTargetSame}
                            />
                        </Col>

                        {/* Size Range */}
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formRange">
                                <Form.Label>Size: {rangeValue}</Form.Label>
                                <Form.Range
                                    min={5}
                                    max={20}
                                    value={rangeValue}
                                    onChange={(e) => setRangeValue(e.target.value)}
                                />
                            </Form.Group>
                        </Col>

                        {isSourceTargetSame && (
                            <p style={{ color: 'red' }}>Source and Target language cannot be the same.</p>
                        )}
                        {!isFormValid && (selectedSourceLanguage === "" || selectedTargetLanguage === "") && (
                            <p style={{ color: 'red' }}>Both Source and Target languages are required.</p>
                        )}
                    </Row>
                </Form>

                <div className="d-flex justify-content-end mt-3">
                    <div>
                        <Button className='button-style border-0 py-2 px-4' onClick={handleSubmit}>
                            Create
                        </Button>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default ManualVocabulary;
