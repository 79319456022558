import React from 'react'
const Thanks = () => {
    return (
        <div>
            <>
                <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                    <div className='text-center'>
                        <h1 className="font-bold">Your assignment has been submitted successfully. You can now quit the Safe Browser.</h1>
                    </div>
                </div>

            </>
        </div>
    )
}

export default Thanks
