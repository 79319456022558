// const parseJwt = (token) => {
//     try {
//         return JSON.parse(atob(token.split('.')[1]));
//     } catch (e) {
//         return null;
//     }
// };

export function AuthHeader() {
    const loggedInUser = JSON.parse(localStorage.getItem('user'));
    return (loggedInUser && loggedInUser.token) ? { "x-access-token": loggedInUser.token } : {}
}
export const GetUserRoles = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    return loggedInUser.role || [];
};

export const AuthVerify = () => {
    const loggedInUser = JSON.parse(localStorage?.getItem("user"));
    if (loggedInUser && loggedInUser?.token) {
        return true
    } else return false;
}