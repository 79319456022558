import React from 'react'

const PageHeading = ({ heading, text, className='' }) => {
    return (
        <>
            <h1
                className={`pt-2 mb-0 text-capitalize ${className}`}
                style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "36px",
                    color: "#303972"
                }}
            >{heading}</h1>
            {text && (
                <p className=' mb-1 text-capitalize'
                    style={{
                        fontSize: "18px",
                        fontWeight:500,
                        lineHeight: "36px",
                        color: "#303972"
                    }}>{text}</p>
            )}

        </>
    )
}

export default PageHeading