import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Image, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthenticationHeader from '../../components/shared/AuthenticationHeader';
import logo from "../../assets/logo.svg";
import leftimage from "../../assets/login.jpg";
import { GoogleAuthLogin, LoginForm } from 'services/authentication/authentication.service';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'utils/AuthContext';
import { TypeAnimation } from 'react-type-animation';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../../assets/google-icon.svg';
import { createSubmission } from 'services/Student/Submission.services';

const SafeLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { isVerified, verifyAuth, userRoles } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const assignmentId = params.get('assignmentId');
    console.log("ID ass", assignmentId)

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = { email, password, assignmentId };

        const toastId = toast.loading('loading...');

        try {
            const response = await LoginForm(data);
            const user = response.data.result;
            toast.update(toastId, {
                render: 'User logged in successfully.',
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            });
            localStorage.setItem('user', JSON.stringify(user));
            verifyAuth();
            // navigate(
            //     userRoles === "superUser"
            //         ? "/user-management"
            //         : userRoles === "teacher"
            //             ? "/dashboard"
            //             : userRoles === "user"
            //                 ? "/dashboard"
            //                 : "/"
            // );
            try {
                const response = await createSubmission({
                    assignmentId: assignmentId,
                });
                console.log("response submission", response.data);
                navigate(`/Safequiz/attempt/${response.data?.result?._id}`);
            } catch (error) {
                console.error(error);
                navigate(`/not-exist`);
            }
        } catch (error) {
            toast.update(toastId, {
                render: `Error: ${error.response.data.error}`,
                type: 'error',
                isLoading: false,
                autoClose: 5000,
            });
            navigate(`/not-exist`);
        }
    };

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            console.log(credentialResponse);
            const response = await GoogleAuthLogin({ token: credentialResponse.credential, assignmentId });
            const user = response.data.result;
            localStorage.setItem('user', JSON.stringify(user));
            verifyAuth();
            // navigate(
            //     userRoles === "superUser"
            //         ? "/user-management"
            //         : userRoles === "teacher"
            //             ? "/dashboard"
            //             : userRoles === "user"
            //                 ? "/dashboard"
            //                 : "/"
            // );
            toast.success('Logged in successfully');
            try {
                const response = await createSubmission({
                    assignmentId: assignmentId,
                });
                console.log("response submission", response.data);
                navigate(`/Safequiz/attempt/${response.data?.result?._id}`);
            } catch (error) {
                console.error(error);
                navigate(`/not-exist`);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.error || 'Google SafeLogin failed');
            navigate(`/not-exist`);
        }
    };

    return (
        <Container fluid style={{ minHeight: '100vh' }}>
            <Row>
                <Col xs={12} md={6} className='p-0 d-none d-md-block position-relative'>
                    <Image
                        src={leftimage}
                        alt='Signup Illustration'
                        className='w-100'
                        style={{ height: '100vh', objectFit: 'cover' }}
                    />
                    <div className='overlay position-absolute w-100 h-100' style={{ top: 0, left: 0, backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <div className='text-overlay position-absolute d-flex flex-column justify-content-center align-items-start' style={{ height: '100vh', left: '5%', top: 0 }}>
                            <div>
                                <div className=" text-white  mb-2 text-uppercase">
                                    <h1 className='mb-3' style={{ fontSize: "50px", fontWeight: "800", color: "#ed4e4f" }}>Fun meets<br /> knowledge!</h1>
                                </div>
                            </div>

                            <TypeAnimation
                                sequence={[
                                    'Learn While Playing Games!',
                                    1000,
                                    'Explore Animal Facts in Fun Quizzes',
                                    1000,
                                    'Test Your Knowledge with Challenges',
                                    1000,
                                    'Discover New Things with Every Level',
                                    1000
                                ]}
                                wrapper="div"
                                speed={50}
                                style={{ fontSize: '32px', display: 'inline-block', fontWeight: '600', color: 'white' }}
                                repeat={Infinity}
                            />
                        </div>
                    </div>

                </Col>
                <Col xs={12} md={6} className='d-flex align-items-center justify-content-center p-4 authentication-form'>
                    <Col xs={12} lg={7}>
                        <AuthenticationHeader
                            image={logo}
                            text={'Log into your Account'}
                        />
                        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                                <Form.Label>Username or Email</Form.Label>
                                <Form.Control
                                    type='email'
                                    placeholder='Enter email'
                                    className='px-3 py-2'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className='mb-1 position-relative' controlId='formBasicPassword'>
                                <Form.Label>Password</Form.Label>
                                <InputGroup >
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Enter password'
                                        className='px-3 py-2 border-right-0'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <InputGroup.Text
                                        id='basic-addon2'
                                        onClick={handleTogglePassword}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Button style={{ fontSize: "15px" }} variant='primary' type='submit' className='mt-3 w-100 button-62 py-2 border-0'>
                                Sign In
                            </Button>
                            <hr>
                            </hr>
                            <Row className="mt-3 google-btn  justify-content-between align-items-center ">
                                <Col xs={12} lg={6} className='p-0'>
                                    <GoogleLogin
                                        onSuccess={handleGoogleLogin}
                                        theme="filled_black"
                                        shape="pill"
                                        text="signin_with"
                                        width={90}
                                        useOneTap={true}
                                        onError={() => toast.error("Google Sign-In failed")}
                                    />
                                </Col>
                                <Col className='mt-3 mt-lg-0' xs={12} md={12} lg={6}>
                                    <Button style={{ fontSize: "15px", background: "#392C26" }} variant='primary' disabled={true} className='w-100 button-style py-2 border-0 '>
                                        Sign In with Microsoft
                                    </Button>
                                </Col>


                            </Row>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </Container>
    );
};

export default SafeLogin;
