import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DynamicModal = ({ show, handleClose, handleConfirm, id, icon = faInfo, denyText = "Cancel", confirmText = "Confirm", heading = "Are you sure?", description = "This is a sample modal", cancelBtn = true }) => {
    console.log("Cancel btn", cancelBtn)
    return (
        <Modal show={show} onHide={handleClose} centered size={"md"} backdrop='static'>
            <Modal.Header className={`border-0 ${cancelBtn ? 'justify-content-center' : ''}`} closeButton={cancelBtn}>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <FontAwesomeIcon
                    className='p-3'
                    icon={icon}
                    style={{
                        fontSize: "40px",
                        color: "red",
                        border: "3px solid red",
                        borderRadius: "60px",
                        width: "40px",
                        height: "40px"
                    }}
                />
                <h3 className='mt-1'> {heading}</h3>
                <p className='text-muted'>
                    {description}
                </p>
            </Modal.Body>
            <Modal.Footer className={`border-0 pt-0 ${cancelBtn ? 'justify-content-between' : 'justify-content-center'}`}>
                {cancelBtn &&
                    <Button
                        variant="secondary"
                        type='button'
                        className='mt-1 px-4 button-style py-2 border-0'
                        style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                        onClick={handleClose}
                    >
                        {denyText}
                    </Button>
                }
                <Button
                    variant="primary"
                    type='button'
                    className='mt-1 px-4 button-style py-2 border-0'
                    onClick={() => handleConfirm(id)}
                >
                    {confirmText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DynamicModal;
