import BreadCrumbs from "components/shared/BreadCrumbs";
import PageHeading from "components/shared/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row, Placeholder, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getSubmission } from "services/Student/Submission.services";

const ViewSubmission = () => {
  const [exerciseInfo, setExerciseInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedAnswers, setSubmittedAnswers] = useState({});
  const [audioUrl, setAudioUrl] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const id = params._id;

  const breadcrumbItems = [{ label: "Assignments", href: "/assignments" }];

  useEffect(() => {
    const fetchSubmittedAnswers = async () => {
      try {
        setLoading(true);
        const response = await getSubmission(id);
        const { exercise, answers } = response?.data?.result;

        setAudioUrl(exercise?.audioUrl?.url);

        // Map the submitted answers
        const answersMap = {};
        answers.forEach((ans) => {
          answersMap[ans.question] = ans.answer;
        });

        setSubmittedAnswers(answersMap);
        setExerciseInfo(exercise);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching submission details:", error);
      }
    };

    fetchSubmittedAnswers();
  }, [id]);

  const isWritingExercise = exerciseInfo?.exerciseType === "writing";
  const isReadingExercise = exerciseInfo?.exerciseType === "reading";
  const isListeningExercise = exerciseInfo?.exerciseType === "listening";

  return (
    <>
      <BreadCrumbs items={breadcrumbItems} />

      <div>
        {exerciseInfo ? (
          <PageHeading
            heading={exerciseInfo?.exerciseType}
            text={`Title: ${exerciseInfo?.title}`}
          />
        ) : (
          <Placeholder className="mb-3" as="div" animation="glow">
            <Placeholder xs={3} className="mb-2" />
            <div>
              <Placeholder xs={6} />
            </div>
          </Placeholder>
        )}
      </div>

      <div
        style={{
          height: "65vh",
          border: "1px solid #A7A7A7",
          borderRadius: "30px",
          padding: "30px",
          boxShadow: "rgba(234, 79, 80, 0.1) 0px 4px 12px",
        }}
      >
        <Row className="h-100">
          {isReadingExercise && (
            <Col
              xs={6}
              style={{
                borderRight: "2px solid #8A8A8A",
                height: "52vh",
              }}
            >
              {exerciseInfo ? (
                <>
                  <h3 className="text-capitalize">Comprehension Passage</h3>
                  <div
                    className="custom-scrollbar"
                    style={{
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    <p>{exerciseInfo?.passage}</p>
                  </div>
                </>
              ) : (
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={6} className="mb-2" />
                  <Placeholder xs={8} />
                  <Placeholder xs={4} />
                  <Placeholder xs={7} />
                </Placeholder>
              )}
            </Col>
          )}

          {isListeningExercise && (
            <Col
              xs={6}
              className="custom-scrollbar"
              style={{
                height: "60vh",
                overflow: "auto",
                borderRight: "2px solid #8A8A8A",
              }}
            >
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h4>{exerciseInfo.title}</h4>
                  <span className={`bg-primary px-3 mx-2 rounded-4 text-white`}>
                    {exerciseInfo.status}
                  </span> */}
                <h5>
                  Listening Exercise Audio:
                </h5>
              </div>
              <div className="">
                <audio controls src={audioUrl} className="mt-5 w-100" />
              </div>
            </Col>
          )}

          <Col
            xs={isWritingExercise ? 12 : 6}
            className="custom-scrollbar"
            style={{ height: "60vh", overflow: "auto" }}
          >
            {exerciseInfo?.questions.map((question, index) => (
              <div
                className="answers"
                key={question._id}
                style={{ marginBottom: "20px" }}
              >
                <h3 className="text-capitalize mb-3">
                  {`Question ${index + 1} of ${exerciseInfo?.questions.length}`}
                </h3>

                <h5 style={{ fontWeight: "700" }} className="text-capitalize">
                  {question.type.replace("-", " ")}
                </h5>

                <h6>{`Question: ${question.questionText}`}</h6>

                {question.type === "multiple-choice" && (
                  <Form>
                    {question.options.map((option, i) => (
                      <Form.Check
                        type="radio"
                        label={option}
                        name={`question-${question._id}`}
                        key={i}
                        checked={submittedAnswers[question._id] === option}
                        readOnly
                      />
                    ))}
                  </Form>
                )}

                {question.type === "true-false" && (
                  <Form>
                    <Form.Check
                      type="radio"
                      label="True"
                      name={`question-${question._id}`}
                      checked={submittedAnswers[question._id] === "True"}
                      readOnly
                    />
                    <Form.Check
                      type="radio"
                      label="False"
                      name={`question-${question._id}`}
                      checked={submittedAnswers[question._id] === "False"}
                      readOnly
                    />
                  </Form>
                )}

                {["written-answer", "fill-in-the-blank"].includes(
                  question.type
                ) && (
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        value={submittedAnswers[question._id] || ""}
                        readOnly
                      />
                    </Form.Group>
                  )}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewSubmission;
